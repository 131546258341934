import { useInfiniteQuery, useMutation, useQuery } from "react-query";

import { User, UserInput, MetaPagination, Param } from "../types";
import {
  handleBasicPost,
  handleDelete,
  handleDetail,
  handleInfiniteList,
  handleSave,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useInfiniteList: (query?: string) => {
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "search",
        value: query,
      });
    }

    return useInfiniteQuery({
      queryKey: ["users"],
      queryFn: handleInfiniteList<User>({ baseUrl: "users", params }),
      keepPreviousData: false,
      getNextPageParam: (lastPage, pages) => lastPage.meta.nextPage,
      getPreviousPageParam: (firstPage, pages) => firstPage.meta.prevPage,
    });
  },
  useDetail: (id?: number | string) => {
    return useQuery({
      queryKey: ["user", id?.toString()],
      queryFn: () => handleDetail<User>({ id, baseUrl: "users" }),
      enabled: !!id,
    });
  },
  useSave: (input: UserInput) => {
    return useMutation<User>({
      mutationFn: () => handleSave({ baseUrl: "users", input }),
      // onError: handleMutationError(enqueueSnackbar),
      retry: 1,
      onSuccess: async data => {
        await queryClient.refetchQueries(["users"]);
        queryClient.setQueryData(
          ["user", data?.id?.toString()],
          (oldData: any) => {
            return data;
          }
        );
      },
    });
  },
  useDelete: (id: number | string) => {
    return useMutation<{ id: number }>({
      mutationFn: () => handleDelete({ baseUrl: "users", id }),
      onSuccess: async ({ id }: { id: number }) => {
        queryClient.setQueryData(["users"], (oldData: any) => {
          const newPages = oldData.pages.map(
            (group: { data: User[]; meta: MetaPagination }) => ({
              data: group.data.filter(
                user => user.id?.toString() !== id?.toString()
              ),
              meta: group.meta,
            })
          );
          return {
            pages: newPages,
            pageParams: oldData.pageParams,
          };
        });
      },
    });
  },
  sendPasswordReset: async (id: number | string) => {
    return await handleBasicPost({
      url: `users/${id}/send_password_reset`,
      input: {},
    });
  },
};
export default methods;
