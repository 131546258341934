import { Commodity, EvaluableType } from "../../types";
import MultiEvaluationApi from "../../api/MultiEvaluation.api";
import LabelApi from "../../api/Label.api";
import { Grid, CircularProgress } from "@mui/material";
import MultiEvaluation from "./MultiEvaluation";

export default function MultiEvaluations({
  commodity,
  multiEvaluableId,
  multiEvaluableType,
}: {
  commodity: Commodity;
  multiEvaluableId: number;
  multiEvaluableType: EvaluableType;
}) {
  const { data: labels } = LabelApi.useListByCommodity(commodity.id);

  const { data: multiEvaluations } = MultiEvaluationApi.useList({
    multiEvaluableType,
    multiEvaluableId,
  });

  return (
    <>
      {labels && multiEvaluations ? (
        <>
          {labels.map(label => (
            <Grid item xs={12} sm={6} md={4} key={label.id}>
              <MultiEvaluation
                multiEvaluableId={multiEvaluableId}
                multiEvaluableType={multiEvaluableType}
                label={label}
                key={label.id}
                multiEvaluations={multiEvaluations.filter(
                  multiEvaluation =>
                    multiEvaluation.labelId.toString() === label.id.toString()
                )}
              />
            </Grid>
          ))}
        </>
      ) : (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}
    </>
  );
}
