import { Box, Card, CardContent, Typography } from "@mui/material";
import {
  TrialVariety,
  FieldVariety,
  EvaluableType,
  ReportType,
} from "../../types";
import TrialApi from "../../api/Trial.api";
import FieldVarietyApi from "../../api/FieldVariety.api";
import EvaluationReportCard from "../trials/EvaluationReportCard";
import MultiEvaluationReportCard from "../trials/MultiEvaluationReportCard";
import CommentCard from "../../ui/CommentCard";
import dayjs from "dayjs";
import { useReportCompareContext } from "../../contexts/ReportCompare.context";
import { getColor } from "../../utils";
import ScrollableImageCard from "../trials/ScrollableImageCard";

export default function TrialEvaluationColumn({
  reportType,
  typeVariety,
  evaluableType,
}: {
  reportType: ReportType;
  evaluableType: EvaluableType;
  typeVariety: TrialVariety | FieldVariety;
}) {
  const {
    mainVarieties,
    displayGrower,
    displaySupplier,
    displayRanch,
    displayShipper,
  } = useReportCompareContext();

  const evalColor = () => {
    if (reportType === "single") return "#fff";
    const colorIndex =
      mainVarieties &&
      Object.keys(mainVarieties).findIndex(
        vId => vId === typeVariety?.variety?.id?.toString()
      );
    return colorIndex !== undefined ? getColor(colorIndex) : "#fff";
  };

  const { data: trial } = TrialApi.useDetail(typeVariety.trialId);
  const { data: fieldVariety } = FieldVarietyApi.useDetail({
    trialId: typeVariety.trialId,

    fieldVarietyId:
      evaluableType === "trial_variety"
        ? // @ts-ignore
          typeVariety?.fieldVarietyId
        : undefined,
  });

  const color = evalColor();

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        gap: "1rem",
        alignItems: "stretch",
      }}
    >
      {trial && (
        <Box sx={{ flex: 1 }}>
          <Card style={{ backgroundColor: color }}>
            <CardContent>
              <Typography variant={"body1"}>Trial {trial?.name}</Typography>
              {displayGrower && (
                <Typography variant={"body1"}>
                  Grower: {trial?.grower?.name}
                </Typography>
              )}
              {displayShipper && (
                <Typography variant={"body1"}>
                  Shipper: {trial?.shipper?.name}
                </Typography>
              )}
              <Typography variant={"body1"}>
                Region: {trial?.region?.name}
              </Typography>
              <Typography variant={"body1"}>
                City: {trial?.area?.name}
              </Typography>
              <Typography variant={"body1"}>
                Commodity: {trial?.commodity?.name}
              </Typography>
              <Typography variant={"body1"}>
                Cultural Practice: {trial?.culturalPractice}
              </Typography>
              {displayRanch && (
                <Typography variant={"body1"}>
                  Ranch: {trial?.ranch?.name}
                </Typography>
              )}
              <Typography variant={"body1"}>Lot: {trial?.lot}</Typography>
              <Typography variant={"body1"}>
                Wet Date: {dayjs(trial?.wetDate).format("MMM D, YYYY")}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      )}
      <Box sx={{ flex: 1 }}>
        <Card style={{ backgroundColor: color }}>
          <CardContent>
            <Typography variant={"body1"}>
              Variety: {typeVariety?.variety?.name}
            </Typography>
            {displaySupplier && (
              <Typography variant={"body1"}>
                Supplier: {typeVariety?.variety?.company?.name}
              </Typography>
            )}
            <Typography variant={"body1"}>
              Maturity Date:{" "}
              {dayjs(typeVariety?.maturityDate).format("MMM D, YYYY")}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ flex: 1 }}>
        <EvaluationReportCard
          typeVariety={typeVariety}
          evaluableType={evaluableType}
          color={color}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <MultiEvaluationReportCard
          typeVariety={typeVariety}
          evaluableType={evaluableType}
          color={color}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <CommentCard
          title={"Comments"}
          comments={typeVariety.comments}
          color={color}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <CommentCard
          title={"Grower Comments"}
          comments={typeVariety.growerComments}
          color={color}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Card style={{ backgroundColor: color }}>
          <CardContent>
            <Typography variant={"body1"}>Scores</Typography>
            <Typography variant={"body1"}>
              Trial Variety: {typeVariety.score}
            </Typography>
            {evaluableType === "trial_variety" && (
              <Typography variant={"body1"}>
                {fieldVariety
                  ? `Field Variety: ${fieldVariety?.score}`
                  : "No associated Field Variety"}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ flex: 1 }}>
        <ScrollableImageCard
          parentId={typeVariety.id}
          parentType={evaluableType}
          color={color}
        />
      </Box>
    </Box>
  );
}
