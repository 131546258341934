import {
  Card,
  CardContent,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import { EvaluableType } from "../../types";
import ImageApi from "../../api/Image.api";

export default function ScrollableImageCard({
  parentId,
  parentType,
  color,
}: {
  parentId: number;
  parentType: EvaluableType;
  color?: string;
}) {
  const { data: images } = ImageApi.useList({ parentId, parentType });
  return (
    <Card style={{ backgroundColor: color, overflow: 'auto' }}>
      <CardContent>
        {images && images.length > 0 ? (
          <ImageList sx={{
            gridAutoFlow: "column",
            gridTemplateColumns: "repeat(auto-fit, minmax(160px,1fr)) !important",
            gridAutoColumns: "minmax(100px, 1fr)",
            overflowX: 'scroll'
          }}>
            {images.map(image => (
              <ImageListItem key={image.id}>
                <img src={image.url} alt="Evaluation" loading={"lazy"} className="report-column-img" />
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <Typography variant="subtitle1">No images</Typography>
        )}
      </CardContent>
    </Card>
  );
}
