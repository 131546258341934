import { Autocomplete, TextField, Typography, Box } from "@mui/material";
import { Variety } from "../types";
import React from "react";
import VarietyApi from "../api/Variety.api";
import { grey } from '@mui/material/colors';

type Option = {
  label: string;
  value: Variety;
};

export default function VarietySelect({
  variety,
  setVariety,
  label,
}: {
  variety?: Variety;
  setVariety: (arg: Variety) => void;
  label: string;
}) {
  const [value, setValue] = React.useState<Option | null>(
    variety ? { label: variety.name, value: variety } : null
  );
  const [inputValue, setInputValue] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);

  const { data, isFetching } = VarietyApi.useList({ query: inputValue });

  const options: Option[] = data
    ? data.data.map((variety: Variety) => ({
        label: variety.name,
        value: variety,
      }))
    : [];
  return (
    <Autocomplete
      autoHighlight
      loading={isFetching}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(event: any, newValue: Option | null) => {
        setValue(newValue);
        setVariety(newValue?.value as Variety);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      renderInput={params => <TextField {...params} label={label} />}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              alignContent: "flex-start",
              alignSelf: "flex-start",
              gap: "0.2rem"
            }}
            {...optionProps}
          >
            <Typography variant="body1">{option.label}</Typography>
            <Typography variant="body2" color={grey[500]}>{option.value?.company?.name}</Typography>
          </Box>
        );
      }}
    />
  );
}
