import { EvaluableType, Image } from "../types";
import { handleBasicDelete, handleSimpleList } from "./handler";
import { useMutation, useQuery } from "react-query";
import queryClient from "./queryClient";

const methods = {
  useList: ({
    parentId,
    parentType,
  }: {
    parentId: number;
    parentType: EvaluableType;
  }) => {
    const baseUrl =
      parentType === "field_variety" ? "field_varieties" : "trial_varieties";
    return useQuery<Image[]>({
      cacheTime: 0,
      queryKey: ["images", parentType, parentId.toString()],
      queryFn: () =>
        handleSimpleList<Image>({
          url: `${baseUrl}/${parentId}/images`,
        }),
    });
  },
  useDelete: ({
    parentId,
    parentType,
  }: {
    parentId: number;
    parentType: EvaluableType;
  }) => {
    const baseUrl =
      parentType === "field_variety" ? "field_varieties" : "trial_varieties";
    return useMutation({
      mutationFn: (imageId: number) =>
        handleBasicDelete({
          url: `${baseUrl}/${parentId}/image_delete`,
          params: [{ key: "image_id", value: imageId }],
        }),
      onSuccess: async (data: Image[]) => {
        queryClient.invalidateQueries({ queryKey: ["images", parentType, parentId.toString()] })
        // queryClient.setQueryData(
        //   ["images", parentType, parentId.toString()],
        //   (oldData: Image[] | undefined) => {
        //     return data;
        //   }
        // );
      },
    });
  },
};

export default methods;
