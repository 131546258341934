import { Typography } from '@mui/material'
import {
  FieldVariety,
  TrialVariety,
} from "../../types";
import { useEvaluationContext } from "../../contexts/Evaluation.context";

export default function EvaluationScore({ typeVariety }: { typeVariety: FieldVariety | TrialVariety }){
  const { getEvaluationScore } = useEvaluationContext();

  const score = getEvaluationScore ? getEvaluationScore() : 0;

  return (
    <Typography variant={"body1"}>
      Weighted Score: {score}
    </Typography>
  )
}