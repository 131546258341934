import {
  EvaluableType,
  FieldVariety,
  Label,
  MultiEvaluation,
  TrialVariety,
} from "../../types";
import {
  Card,
  CardContent,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import MultiEvaluationApi from "../../api/MultiEvaluation.api";

export default function MultiEvaluationReportCard({
  typeVariety,
  evaluableType,
  color,
}: {
  typeVariety: TrialVariety | FieldVariety;
  evaluableType: EvaluableType;
  color?: string;
}) {
  const { data: multiEvaluations } = MultiEvaluationApi.useList({
    multiEvaluableType: evaluableType,
    multiEvaluableId: typeVariety.id,
  });

  const tagNames = (labelId: string) => {
    const tagNames: { [key: string]: number } = {};
    multiEvaluations &&
      multiEvaluations
        .filter((multiEval: MultiEvaluation) =>
          multiEval.archived
            ? multiEval.archivedLabelId === parseInt(labelId)
            : multiEval.labelId === parseInt(labelId)
        )
        .forEach((multiEval: MultiEvaluation) => {
          tagNames[multiEval?.tag?.name] = multiEval?.tag?.location;
        });
    return Object.keys(tagNames).sort((a, b) => {
      const aLoc = tagNames[a];
      const bLoc = tagNames[b];
      if (aLoc < bLoc) {
        return -1;
      }
      if (aLoc > bLoc) {
        return 1;
      }
      return 0;
    });
  };

  const labels: { [key: string]: Label } = {};
  multiEvaluations &&
    multiEvaluations.forEach(multiEval => {
      labels[multiEval.labelId.toString()] = multiEval?.label;
    });

  return (
    <Card style={{ backgroundColor: color, height: "100%" }}>
      <CardContent>
        <Typography variant="subtitle1">Diseases</Typography>
        {Object.keys(labels).map((labelId: string) => {
          const label = labels[labelId];
          return (
            <ListItem key={labelId} sx={{p:0}}>
              <ListItemText
                secondary={`${label?.name} : ${tagNames(labelId).join(", ")}`}
              />
            </ListItem>
          );
        })}
      </CardContent>
    </Card>
  );
}
