import { useInfiniteQuery, useMutation, useQuery } from "react-query";

import { MetaPagination, Param, Label, LabelInput } from "../types";
import {
  handleBasicPost,
  handleDelete,
  handleDetail,
  handleInfiniteList,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useInfiniteList: (query?: string) => {
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "search",
        value: query,
      });
    }
    return useInfiniteQuery<{ data: Label[]; meta: MetaPagination }>({
      queryKey: ["labels_infinite"],
      queryFn: handleInfiniteList<Label>({ baseUrl: "labels", params }),
      keepPreviousData: false,
      getNextPageParam: (lastPage, pages) => lastPage.meta.nextPage,
      getPreviousPageParam: (firstPage, pages) => firstPage.meta.prevPage,
    });
  },
  useListByCommodity: (commodityId?: number | string) => {
    return useQuery<Label[]>({
      enabled: !!commodityId,
      queryKey: ["labels_by_commodity", commodityId?.toString()],
      queryFn: () =>
        handleSimpleList<Label>({
          url: "labels/by_commodity_id",
          params: [{ key: "commodity_id", value: commodityId }],
        }),
    });
  },
  useAddLabelToCommodity: ({ commodityId }: { commodityId: number }) => {
    return useMutation({
      mutationFn: ({ labelId }: { labelId: number }) => {
        return handleBasicPost({
          url: `commodities/${commodityId}/add_label`,
          input: { label_id: labelId },
        });
      },
      retry: 1,
      onSuccess: async (data: Label[]) => {
        queryClient.setQueryData(
          ["labels_by_commodity", commodityId.toString()],
          () => {
            return data;
          }
        );
      },
    });
  },
  useRemoveLabelFromCommodity: ({ commodityId }: { commodityId: number }) => {
    return useMutation({
      mutationFn: ({ labelId }: { labelId: number }) => {
        return handleBasicPost({
          url: `commodities/${commodityId}/remove_label`,
          input: { label_id: labelId },
        });
      },
      retry: 1,
      onSuccess: async (data: Label[]) => {
        queryClient.setQueryData(
          ["labels_by_commodity", commodityId.toString()],
          () => {
            return data;
          }
        );
      },
    });
  },
  useDetail: (id?: number | string) => {
    return useQuery<Label>({
      queryFn: () => handleDetail({ baseUrl: "labels", id }),
      queryKey: ["label", id?.toString()],
      enabled: !!id,
    });
  },
  useSave: (input: LabelInput) => {
    return useMutation<Label>({
      mutationFn: () => handleSave({ baseUrl: "labels", input }),
      onSuccess: async data => {
        await queryClient.refetchQueries(["labels_infinite"]);
        queryClient.setQueryData(
          ["label", data?.id?.toString()],
          (oldData: any) => {
            return data;
          }
        );
      },
    });
  },
  useDelete: (id?: number | string) => {
    return useMutation<{ id: number }>({
      mutationFn: () => handleDelete({ baseUrl: "labels", id }),
      onSuccess: async ({ id }: { id: number }) => {
        queryClient.setQueryData(["labels_infinite"], (oldData: any) => {
          const newPages = oldData.pages.map(
            (group: { data: Label[]; meta: MetaPagination }) => ({
              data: group.data.filter(
                obj => obj.id?.toString() !== id?.toString()
              ),
              meta: group.meta,
            })
          );
          return {
            pages: newPages,
            pageParams: oldData.pageParams,
          };
        });
      },
    });
  },
};

export default methods;
