import StatisticsApi from "../../api/Statistics.api";
import { Typography } from "@mui/material";
import dayjs, { Dayjs } from 'dayjs'
import { Commodity, CulturalPractice } from "../../types";

export default function RecommendHarvestDate({
  commodity,
  culturalPractice,
  transplantDate,
  wetDate,
}: {
  commodity?: Commodity,
  culturalPractice: CulturalPractice,
  transplantDate: Dayjs | null,
  wetDate: Dayjs | null
}){
  const { data } = StatisticsApi.useEstHarvestDate({
    commodityId: commodity?.id,
    culturalPractice: culturalPractice,
    dateType: transplantDate ? "transplant" : "wet",
    searchDate: transplantDate ? dayjs(transplantDate).toDate() : dayjs(wetDate).toDate()
  })

  let date = "Not Available"
  if(data && data?.tempEstimatedHarvestDate){
    date = dayjs(data.tempEstimatedHarvestDate).format("MMM D, YYYY")
  }

  return (
    <>
      <Typography variant="caption">Recommended Harvest Date {date}</Typography>
    </>
  )
}