import { useInfiniteQuery, useMutation, useQuery } from "react-query";

import { Nursery, NurseryInput, MetaPagination, Param } from "../types";
import {
  handleDelete,
  handleDetail,
  handleInfiniteList,
  handleSave,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useInfiniteList: (query?: string) => {
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "search",
        value: query,
      });
    }
    return useInfiniteQuery<{ data: Nursery[]; meta: MetaPagination }>({
      queryKey: ["nurseries"],
      queryFn: handleInfiniteList<Nursery>({ baseUrl: "nurseries", params }),
      keepPreviousData: false,
      getNextPageParam: (lastPage, pages) => lastPage.meta.nextPage,
      getPreviousPageParam: (firstPage, pages) => firstPage.meta.prevPage,
    });
  },
  useDetail: (id?: number | string) => {
    return useQuery<Nursery>({
      queryKey: ["nursery", id?.toString()],
      queryFn: () => handleDetail({ baseUrl: "nurseries", id }),
      enabled: !!id,
    });
  },
  useSave: (nurseryInput: NurseryInput) => {
    return useMutation<Nursery>({
      mutationFn: () =>
        handleSave({ baseUrl: "nurseries", input: nurseryInput }),
      onSuccess: async (data: Nursery) => {
        queryClient.setQueryData(["nursery", data.id?.toString()], data);
        queryClient.setQueryData(
          ["nursery", data?.id?.toString()],
          (oldData: any) => {
            return data;
          }
        );
      },
    });
  },
  useDelete: (id: number | string) => {
    return useMutation<{ id: number }>({
      mutationFn: () => handleDelete({ baseUrl: "nurseries", id }),
      onSuccess: async ({ id }: { id: number }) => {
        queryClient.setQueryData(["nurseries"], (oldData: any) => {
          const newPages = oldData.pages.map(
            (group: { data: Nursery[]; meta: MetaPagination }) => ({
              data: group.data.filter(
                obj => obj.id?.toString() !== id?.toString()
              ),
              meta: group.meta,
            })
          );
          return {
            pages: newPages,
            pageParams: oldData.pageParams,
          };
        });
      },
    });
  },
};
export default methods;
