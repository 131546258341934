import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { Commodity, EvaluableType } from "../../types";
import MeasurementEvaluationAverage from "./MeasurementEvaluationAverage";
import { useEvaluationContext } from "../../contexts/Evaluation.context";

export default function MeasurementEvaluations({
  commodity,
  evaluableId,
  evaluableType,
}: {
  commodity: Commodity;
  evaluableId: number;
  evaluableType: EvaluableType;
}) {
  const {
    measurements,
    measurementEvaluations,
    measurementEvaluationsIsLoading,
  } = useEvaluationContext();

  return (
    <>
      {measurements && !measurementEvaluationsIsLoading ? (
        measurements
          .sort((a, b) => a.location - b.location)
          .map(measurement => (
            <MeasurementEvaluationAverage
              key={measurement.id}
              evaluableId={evaluableId}
              evaluableType={evaluableType}
              measurement={measurement}
              measurementEvaluations={
                measurementEvaluations
                  ? measurementEvaluations.filter(
                      me => me.measurementId === measurement.id
                    )
                  : []
              }
            />
          ))
      ) : (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}
    </>
  );
}
