import { useInfiniteQuery, useMutation, useQuery } from "react-query";

import {
  ListResponseData,
  MetaPagination,
  Param,
  Variety,
  VarietyInput,
} from "../types";
import {
  handleBasicPost,
  handleDelete,
  handleDetail,
  handleInfiniteList,
  handleList,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useInfiniteList: (query?: string) => {
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "search",
        value: query,
      });
    }

    return useInfiniteQuery({
      queryKey: ["varieties"],
      queryFn: handleInfiniteList<Variety>({ baseUrl: "varieties", params }),
      // initialPageParam: 0,
      keepPreviousData: false,
      getNextPageParam: (lastPage, pages) => lastPage.meta.nextPage,
      getPreviousPageParam: (firstPage, pages) => firstPage.meta.prevPage,
    });
  },
  useList: ({ query, page }: { query?: string; page?: number }) => {
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "search",
        value: query,
      });
    }
    if (page) {
      params.push({
        key: "page",
        value: page,
      });
    }
    return useQuery<ListResponseData<Variety>>({
      queryKey: ["varieties", query?.toString(), page?.toString()],
      queryFn: () => handleList<Variety>({ baseUrl: "varieties", params }),
    });
  },
  useListWithExpiermentalVarieties: (varietyIds: string[]) => {
    return useQuery<Variety[]>({
      queryKey: ["report_varieties", varietyIds.sort().join('-')],
      queryFn: () =>
        handleSimpleList<Variety>({
          url: "varieties/all_expiermentals",
          params: [{ key: "ids", value: varietyIds.join(",") }],
        }),
    });
  },
  useCombine: (id?: number | string) => {
    return useMutation({
      mutationFn: (varietyIds: string[]) =>
        handleBasicPost({
          url: `varieties/${id}/combine`,
          input: {
            varieties: varietyIds,
          },
        }),
      onSuccess: async data => {
        await queryClient.refetchQueries(["variety", id]);
      },
    });
  },
  useRemoveCombination: (parentVarietyId: number | string) => {
    return useMutation({
      mutationFn: (id: number | string) =>
        handleBasicPost({ url: `varieties/${id}/remove` }),
      onSuccess: async data => {
        await queryClient.refetchQueries(["variety", parentVarietyId]);
      },
    });
  },
  useDetail: (id?: number | string) => {
    return useQuery({
      queryKey: ["variety", id?.toString()],
      queryFn: () => handleDetail<Variety>({ id, baseUrl: "varieties" }),
      enabled: !!id,
      cacheTime: 0,
    });
  },
  useSave: (input: VarietyInput) => {
    return useMutation<Variety>({
      mutationFn: () => handleSave({ baseUrl: "varieties", input }),
      retry: 1,
      onSuccess: async data => {
        await queryClient.refetchQueries(["varieties"]);
        queryClient.setQueryData(
          ["variety", data?.id?.toString()],
          (oldData: any) => {
            return data;
          }
        );
      },
    });
  },
  useDelete: (id: number | string) => {
    return useMutation<{ id: number }>({
      mutationFn: () => handleDelete({ baseUrl: "varieties", id }),
      onSuccess: async ({ id }: { id: number }) => {
        queryClient.setQueryData(["varieties"], (oldData: any) => {
          const newPages = oldData.pages.map(
            (group: { data: Variety[]; meta: MetaPagination }) => ({
              data: group.data.filter(
                variety => variety.id?.toString() !== id?.toString()
              ),
              meta: group.meta,
            })
          );

          return {
            pages: newPages,
          };
        });
      },
    });
  },
};
export default methods;
