import React from 'react'
import * as Sentry from "@sentry/react";
import {  Box, Button, Card, CardContent, CardActions, TextField } from "@mui/material";
import { Measurement } from '../../types'
import toast from "react-hot-toast";
import { HttpError } from "../../types/http-error";
import MeasurementApi from '../../api/Measurement.api'
import { isNaN } from 'lodash'

export default function MeasurementCard({item}:{ item: Measurement }){
  const [name, setName] = React.useState<string>(item.name || "")
  const [numOfMeasurements, setNumOfMeasurements] = React.useState<number>(item.numOfMeasurements)

  const { mutateAsync: saveAsync } = MeasurementApi.useSave()
  const { mutateAsync: deleteAsync } = MeasurementApi.useDelete({
    commodityId: item.commodityId
  })

  const handleSave = async () => {
    try {
      await saveAsync({
        ...item,
        name,
        numOfMeasurements
      })
      toast.success("Successfully saved.");
    } catch (error){
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to save.");
      }
    }
  }

  const handleDelete = async () => {
    try {
      await deleteAsync(item.id)
      toast.success("Successfully deleted.");
    } catch(error){
      console.error(error)
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to delete.");
      }
    }
  }


  return (
    <Card>
      <CardContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem"}}>
          <TextField
            label="Name"
            value={name}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
          />
          <TextField
            fullWidth
            value={numOfMeasurements}
            onChange={event => {
              const value = event.target.value;
              const parsedValue = parseInt(value);
              if (!isNaN(parsedValue) && parsedValue >= 0) {
                setNumOfMeasurements(parseInt(value));
              } else {
                setNumOfMeasurements(0);
              }
            }}
            label="Number of Measurements Used"
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </CardContent>
      <CardActions>
        <Button fullWidth variant="contained" onClick={handleSave}>Save</Button>
        <Button fullWidth color="error" variant="contained" onClick={handleDelete}>Delete</Button>
      </CardActions>
    </Card>
  )
}