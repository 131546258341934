import React from "react";
import * as Sentry from "@sentry/react";
import { Measurement, EvaluableType, MeasurementEvaluation } from "../../types";
import { Grid, TextField } from "@mui/material";
import { isNaN, isNil, debounce } from "lodash";
import MeasurementEvaluationApi from "../../api/MeasurementEvaluation.api";
import toast from "react-hot-toast";
import { HttpError } from "../../types/http-error";
import { useEvaluationContext } from "../../contexts/Evaluation.context";

export default function MeasurementEvaluationForm({
  measurement,
  evaluableId,
  evaluableType,
  measurementEvaluation,
  measurementIndex,
}: {
  measurement: Measurement;
  evaluableId: number;
  evaluableType: EvaluableType;
  measurementEvaluation?: MeasurementEvaluation;
  measurementIndex: number;
}) {
  const { setMeasurementScoreForAverage } = useEvaluationContext();
  const [value, setValue] = React.useState<number | string>(
    measurementEvaluation?.value || ""
  );

  React.useEffect(() => {
    if(measurementEvaluation && !isNil(measurementEvaluation?.value)) {
      setValue(measurementEvaluation.value)
    }
  }, [measurementEvaluation])

  const { mutateAsync: saveMeasurementEvaluation } =
    MeasurementEvaluationApi.useSave();


  const debouncedChange =
    debounce(async (value) => {
      const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
      if (!validated) {
        return;
      }
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue) && parsedValue >= 0) {
        setValue(parseFloat(value));
        setMeasurementScoreForAverage &&
          setMeasurementScoreForAverage({
            measurementId: measurement.id,
            index: measurementIndex,
            value: parseFloat(value),
          });
      } else {
        setValue("");
        setMeasurementScoreForAverage &&
          setMeasurementScoreForAverage({
            measurementId: measurement.id,
            index: measurementIndex,
            value: undefined,
          });
      }
      try {
        await saveMeasurementEvaluation({
          measurementEvaluableId: evaluableId,
          measurementEvaluableType: evaluableType,
          measurementEvaluationInput: {
            id: measurementEvaluation?.id,
            measurementId: measurement.id,
            measurementIndex,
            value: parseFloat(value),
          },
        });
        toast.success("Successfully saved.");
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        if (error instanceof HttpError && error.message) {
          toast.error(error.message?.split(",").join("\n"));
        } else {
          toast.error("Failed to save measurement evaluation");
        }
      }
    }, 100);

  async function handleChange(event: React.ChangeEvent<HTMLInputElement>){
    debouncedChange(event.target.value)
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <TextField
        label={`${measurement.name} - ${measurementIndex + 1}`}
        value={value}
        variant="outlined"
        onChange={handleChange}
        fullWidth
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
  );
}
