import { handleDelete, handleSave, handleSimpleList } from "./handler";
import { Tag, TagInput } from "../types";
import { useMutation, useQuery } from "react-query";
import queryClient from "./queryClient";
import { unionBy } from 'lodash'

const methods = {
  useList: (labelId?: number | string) => {
    return useQuery<Tag[]>({
      queryFn: () => handleSimpleList({ url: `labels/${labelId}/tags` }),
      queryKey: ["tags", labelId?.toString()],
      enabled: !!labelId,
    });
  },
  useSave: () => {
    return useMutation({
      mutationFn: (input: TagInput) =>
        handleSave<Tag>({ baseUrl: `labels/${input.labelId}/tags`, input }),
      onSuccess: async (data: Tag) => {
        queryClient.setQueryData(
          ["tags", data?.labelId.toString()],
          (oldData: Tag[] | undefined) => {
            if(oldData){
              return unionBy([data], oldData, 'id')
            }
            return [data]
          }
        );
      },
    });
  },
  useDelete: () => {
    return useMutation({
      mutationFn: ({
        id,
        labelId,
      }: {
        id: number | string;
        labelId: number | string;
      }) => handleDelete({ baseUrl: `labels/${labelId}/tags`, id }),
      retry: 1,
    });
  },
};
export default methods;
